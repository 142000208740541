<template>
    <!-- 健康防疫 -->
    <div class="attendance-list">
        <div class="manage-wrapper">
            <div class="table-wrapper">
                <div class="table-title-wrapper flex-align-between">
                    <h2 class="table-title">
                        <img src="../../assets/images/home/health.png" alt="" class="title-icon">健康防疫</h2>
                    <button class="check-in csp" @click="openDialog">
                        <i class="el-icon-plus"></i>防疫登记
                    </button>
                </div>

                <div class="table">
                    <el-table :data="tableData" border style="width: 100%">
                        <el-table-column type="index" label="序号" style="background:#2373EC" align="center"
                            :index="indexMethod">
                        </el-table-column>
                        <el-table-column prop="date" label="日期" align="center">
                            <template slot-scope="scope">
                                {{dayjs(scope.row.date).format('YYYY-MM-DD')}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="time" label="上传时间" align="center"></el-table-column>
                        <el-table-column prop="temperature" label="体温" align="center">
                            <template slot-scope="scope">
                                {{scope.row.temperature || '--'}}℃
                            </template>
                        </el-table-column>
                        <el-table-column prop="state" label="状态" align="center">
                            <template slot-scope="scope">
                                {{healthState[scope.row.state]}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="healthUrl" label="健康宝" align="center">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" :disabled="!scope.row.healthUrlName"
                                    @click="previewImage(scope.row,'healthUrl')">
                                    {{scope.row.healthUrlName || '无'}}</el-button>
                            </template>
                        </el-table-column>
                        <el-table-column prop="travelUrl" label="行程码" align="center">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" :disabled="!scope.row.travelUrlName"
                                    @click="previewImage(scope.row,'travelUrl')">
                                    {{scope.row.travelUrlName || '无'}}</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <pagination v-show="pages.total > 0" :total="pages.total" :page.sync="pages.currentPage"
                        :limit.sync="pages.pageSize" @pagination="handlePageChange">
                    </pagination>
                </div>
            </div>
        </div>
        <!-- destroy-on-close -->
        <el-dialog title="防疫登记" :visible.sync="checkInDialog" width="700px" @close="closeDialog">
            <div slot="title" class="dialog-title">
                <span>防疫登记</span>
                <span class="dialog-title-time">2021-08-21</span>
            </div>
            <!--  :rules="rules" -->
            <el-form ref="createForm" :model="createForm" label-width="180px">
                <el-form-item label="当日体温：" prop="temperature">
                    <el-input v-model.number="createForm.temperature" size="mini" style="width:201px;" placeholder=""
                        maxlength="30"></el-input> ℃
                </el-form-item>
                <el-form-item label="上传图片附件：" prop="healthUrl">
                    <upload @uploadFile="handleFileChange" :name="'healthUrl'" :text="'点击上传北京健康报截图'"
                        :list="echoHealthImageList" />
                </el-form-item>
                <el-form-item label="上传图片附件：" prop="travelUrl" class="trip-code" style="margin-bottom:10px;">
                    <upload @uploadFile="handleFileChange" :name="'travelUrl'" :text="'点击上传行程码截图'"
                        :list="echoTravelImageList" />
                </el-form-item>
                <el-form-item class="code-tips">
                    <p>* 微信登录国务院客户端小程序即可查询行程码</p>
                </el-form-item>
                <el-form-item class="btns-box" label-width="0px">
                    <el-button class="submit btn" @click="coverTips">确定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    import {
        mapState
    } from "vuex";
    import upload from '../manager/components/upload-file.vue';
    // import ElImageViewer from 'element-ui/packages/image/src/image-viewer.vue'
    export default {
        data() {
            var validateTemperature = (rule, value, callback) => {
                if (!value) {
                    callback();
                }
                setTimeout(() => {
                    if (!Number.isInteger(value)) {
                        callback(new Error('请输入数字值'));
                    } else {
                        // if (value > 300) {
                        //     callback(new Error('台下人数不能大于300'));
                        // } else if (value < 0) {
                        //     callback(new Error('台下人数不能小于0'));
                        // } else {
                        //     callback();
                        // }
                        callback();
                    }
                }, 1000);
            }
            return {
                pages: { //分页
                    currentPage: 1,
                    pageSize: 10,
                    total: 0,
                },
                tableData: [],
                checkInDialog: false,
                createForm: {
                    temperature: '',
                    healthUrl: '',
                    healthUrlName: '',
                    travelUrl: '',
                    travelUrlName: '',
                },
                healthState: {
                    0: '待上传',
                    1: '已上传',
                },
                currentHealth: {},
                echoHealthImageList: [],
                echoTravelImageList: [],
                imageList: [],
                rules: {
                    temperature: [{
                        required: true,
                        message: "请输入体温",
                        trigger: "blur",
                        type: 'number',
                        validator: validateTemperature
                    }],
                },
            }
        },
        components: {
            upload,
        },
        computed: {
            ...mapState({
                currentProject: state => state.user.currentProject,
            })
        },
        methods: {
            handleFileChange(data) {
                console.log(data);
                this.createForm[data.name] = data.fileMD5List[0]
                if (JSON.stringify(data.fileList) == '[]') return;
                this.createForm[`${data.name}Name`] = data.fileList[0].name
            },
            indexMethod(index) {
                Common.tableIndexMethod(index, this.pages.currentPage, this.pages.pageSize)
            },
            // 打开签到弹窗
            openDialog() {
                this.checkInDialog = true
                this.getHealthOne();
            },
            // 关闭签到弹窗
            closeDialog() {
                this.currentHealth = {};
                this.echoHealthImageList = [];
                this.echoTravelImageList = [];
                Vue.set(this.createForm, 'temperature', undefined);
                Vue.set(this.createForm, 'healthUrl', undefined);
                Vue.set(this.createForm, 'healthUrlName', undefined);
                Vue.set(this.createForm, 'travelUrl', undefined);
                Vue.set(this.createForm, 'travelUrlName', undefined);
            },
            handlePageChange(data) {
                this.pages.currentPage = data.pageIndex
                this.pages.pageSize = data.pageSize
                this.getHealthByTeacher();
            },
            // 预览图片
            previewImage(item, type) {
                this.imageList = [];
                this.imageList.push(this.downloadURL + item.healthUrl);
                this.imageList.push(this.downloadURL + item.travelUrl);
                let index = type == 'healthUrl' ? 0 : 1;
                this.$previewImgs(this.imageList, index)
            },
            async getHealthByTeacher() {
                let params = {
                    projectId: this.currentProject.id,
                    page: this.pages.currentPage,
                    limit: this.pages.pageSize,
                }
                let resData = await this.$Api.Project.getHealthByTeacher(params);
                // console.log(resData);
                if (JSON.stringify(resData.data.records) == '[]' || !resData) {
                    this.tableData = [];
                    return;
                }
                this.tableData = resData.data.records
                this.pages.total = resData.data.total
            },
            // 获取选中的某一条信息
            async getHealthOne(item) {
                let params = {
                    projectId: this.currentProject.id,
                }
                // params.id = item ? item.id : null;
                let resData = await this.$Api.Project.getHealthOne(params);
                this.currentHealth = resData.data
                this.createForm = this.currentHealth;
                for (const key in this.createForm) {
                    this.createForm[key] = this.currentHealth[key];
                    if (key == 'healthUrl' || key == 'travelUrl') {
                        let name = key == 'healthUrl' ? 'Health' : 'Travel'
                        console.log(`echo${name}ImageList`)
                        this[`echo${name}ImageList`].push({
                            identifier: this.currentHealth[key],
                            filename: this.currentHealth[`${key}Name`]
                        })
                    }
                }
            },
            // 当日二次上传提示
            coverTips() {
                if (this.currentHealth.time) {
                    this.$confirm('您今日已提交，如再次提交原有数据会被覆盖', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.submitHealth();
                        return;
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消提交'
                        });
                        return;
                    });
                } else {
                    this.submitHealth();
                }
            },
            async submitHealth() {
                let params = {
                    id: this.currentHealth.id,
                    date: this.dayjs(this.currentHealth.date).format('YYYY-MM-DD HH:mm:ss'),
                    time: this.dayjs().format('YYYY-MM-DD HH:mm:ss'),
                    projectId: this.currentProject.id,
                    temperature: this.createForm.temperature,
                    healthUrl: this.createForm.healthUrl,
                    healthUrlName: this.createForm.healthUrlName,
                    travelUrl: this.createForm.travelUrl,
                    travelUrlName: this.createForm.travelUrlName,
                    state: 1
                }
                console.log(params);
                // return
                let resData = await this.$Api.Project.editHealth(params);
                if (resData.data.code == 200 && resData.data.data) {
                    this.$message({
                        message: '登记成功',
                        type: 'success'
                    });
                    this.checkInDialog = false
                    this.getHealthByTeacher();
                } else {
                    this.$message({
                        message: `登记失败，请联系管理员 ${resData.msg}`,
                        type: 'error'
                    });
                }
            }
        },
        mounted() {
            this.getHealthByTeacher();
        }
    }
</script>

<style lang="less" scoped>
    @import "../manage/less/table.less";

    .attendance-list {
        .table-wrapper {
            border-radius: 8px;
            padding-top: 10px;
            padding: 0 26px 24px;
            box-shadow: 0px 2px 10px 0px #f5f8fa;
        }

        .table-title {
            line-height: 68px;
            font-size: 16px;
        }

        .title-icon {
            width: 17px;
            height: 18px;
            margin-right: 9px;
            vertical-align: -4px;
        }

        .check-in {
            background-color: #249BFD;
            color: #fff;
            border-radius: 25px;
            line-height: 33px;
            padding: 0 15px;

            .el-icon-plus {
                margin-right: 5px;
            }

            &:hover {
                background-color: rgba(36, 155, 253, .8);
            }
        }

        /deep/ .el-dialog {
            border-radius: 10px;

            .el-dialog__header {
                border-bottom: 1px solid #E4E4E4;
                padding: 15px 31px 10px;
            }

            .el-dialog__headerbtn {
                top: 15px;
            }

            .dialog-title {
                font-size: 16px;
                color: #333;
                position: relative;

                &::before {
                    content: '';
                    width: 5px;
                    height: 17px;
                    background-color: #01A2FD;
                    display: inline-block;
                    margin-right: 14px;
                    vertical-align: -3px;
                    border-radius: 3px;
                }

                .dialog-title-time {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }

            .el-form-item__label {
                color: #333;
                font-weight: 600;
            }

            .qrcode {
                img {
                    margin: 0 auto;
                }
            }

            .qr-tips {
                color: #01A2FD;
                font-size: 15px;
                text-align: center;
                margin: 16px 0;
            }

            .upload-file {
                .el-button {
                    border: 1px solid #01A2FD;
                    color: #01A2FD;
                }

                .el-icon-plus {
                    display: none;
                }

                .el-col-8 {
                    min-width: 201px;
                }
            }

            .trip-code {
                .upload-file .el-button {
                    min-width: 201px;
                }
            }

            .code-tips {
                margin-bottom: 37px;

                .el-form-item__content {
                    line-height: 20px;
                }
            }

            .btns-box {
                text-align: center;
            }

            .submit {
                background: linear-gradient(180deg, #5DA7FF 0%, #4893F9 100%);
                border-radius: 25px;
                color: #fff;
                line-height: 35px;
                padding: 0 63px;
                cursor: pointer;
                border: 0;

                &:hover {
                    background: linear-gradient(180deg, rgba(93, 167, 255, .8) 0%, rgba(72, 147, 249, .8) 100%);
                }
            }
        }

        /deep/.el-icon-circle-close {
            color: #fff;
        }

        @media screen and (min-width:1250px) {
            .table-wrapper {
                padding: 0 43px 24px;
            }
        }
    }
</style>